/**
 * Make fetch request to the server
 * @param {string} url
 * @param {object} data
 * @param {string} token
 * @returns {Promise<any>}
 */
export const postLoginData = async (url = "", data = {}, token) => {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-CSRF-TOKEN": token,
        },
        body: JSON.stringify(data),
    })
    return response.json()
}

/**
 * Validate email
 * @param {string} email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

/**
 * Check touch events support
 * @returns {boolean}
 */
export const isTouchEnabled = () => "ontouchstart" in window || navigator.maxTouchPoints > 0

/**
 * Determine if an element should be lazy loaded
 * @param {Element} element
 * @returns {boolean}
 */
export const shouldBeLazyLoaded = (element) => {
    const triggerOffset = Math.round(window.innerHeight * 1.5)
    const { top: elementTop } = getCoords(element)
    const windowScrollYPos = Math.round(window.scrollY)
    const triggerPosition = Math.round(elementTop) - triggerOffset

    return windowScrollYPos >= triggerPosition
}

/**
 * Get element X and Y position on the page
 * @param {Element} element
 * @returns {{top: number, left: number}}
 */
export const getCoords = (element) => {
    const box = element.getBoundingClientRect()
    const body = document.body
    const docEl = document.documentElement
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft
    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0
    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top, left }
}

/**
 * Get the current year
 * @returns {number}
 */
export const getCurrentYear = () => new Date().getFullYear()

/**
 * Open Login/Register popup on register tab
 * @param {string} type
 */
export const openLoginRegistrationPopup = (type) => {
    window.trialRegistrationForm = true
    setTimeout(() => {
        const registerPopup = document.getElementById("register-popup")
        const aContainer = document.getElementById("a-container")
        const bContainer = document.getElementById("b-container")
        const switchCnt = document.getElementById("switch-cnt")
        const switchC1 = document.getElementById("switch-c1")
        const switchC2 = document.getElementById("switch-c2")
        const signInFormMobile = document.getElementById("signInFormMobile")
        const signUpFormMobile = document.getElementById("signUpFormMobile")

        registerPopup.classList.add("popup-open")
        registerPopup.classList.remove("close-important", "start-position")

        if (type === "registration") {
            aContainer.classList.remove("is-txl")
            bContainer.classList.remove("is-txl", "is-z200")
            switchCnt.classList.remove("is-txr")
            switchC1.classList.remove("is-hidden")
            switchC1.classList.add("active")
            switchC2.classList.add("is-hidden")
            switchC2.classList.remove("active")

            signInFormMobile.className = "not-active"
            signUpFormMobile.className = "active"
        } else {
            signInFormMobile.className = "active"
            signUpFormMobile.className = "not-active"
        }
    }, 420)
}

/**
 * Sort object by key from a to z
 * @param {object} obj
 * @returns {object}
 */
export const sortObjByKey = (obj) => {
    return Object.keys(obj)
        .sort()
        .reduce((result, key) => {
            result[key] = obj[key]
            return result
        }, {})
}

/**
 * Smooth scrolling for all browsers
 * @param {number} eAmt
 * @param {string} where
 */
export const SVS_B = (eAmt, where) => {
    if (where === "center" || where === "") window.scrollBy(0, eAmt / 2)
    if (where === "top") window.scrollBy(0, eAmt)
}

/**
 * Smooth vertical scrolling
 * @param {Element} element
 * @param {number} time
 * @param {string} where
 */
export const SmoothVerticalScrolling = (element, time, where) => {
    const eTop = element.getBoundingClientRect().top
    const eAmt = eTop / 100
    let curTime = 0
    while (curTime <= time) {
        window.setTimeout(SVS_B, curTime, eAmt, where)
        curTime += time / 100
    }
}

/**
 * Smooth scroll to position
 * @param {number} pos
 * @param {number} time
 */
export const scrollToSmoothly = (pos, time = 500) => {
    const currentPos = window.pageYOffset
    let start = null

    window.requestAnimationFrame(function step(currentTime) {
        start = start || currentTime
        const progress = currentTime - start
        const newPos =
            currentPos < pos
                ? ((pos - currentPos) * progress) / time + currentPos
                : currentPos - ((currentPos - pos) * progress) / time

        window.scrollTo(0, newPos)

        if (progress < time) {
            window.requestAnimationFrame(step)
        } else {
            window.scrollTo(0, pos)
        }
    })
}

/**
 * Count lines in an element
 * @param {Element} element
 * @returns {number}
 */
export const countLines = (element) => {
    const style = getComputedStyle(element)
    const divHeight = element.scrollHeight
    const lineHeight = parseInt(style.lineHeight, 10)
    return divHeight / lineHeight
}

/**
 * Detect if the browser is Safari
 */
const detectSafariBrowser = () => {
    if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
        document.body.classList.add("safari-browser")
    }
}

detectSafariBrowser()
